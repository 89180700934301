.page-mode {
  .payment-container {
    background: var(--common-White);
    padding: 0;
    grid-template-columns: 1fr;
    align-items: flex-start;
  }
  .card-block {
    max-width: 100%;
    box-shadow: none;
  }
  .header {
    height: 16rem;
    background-position-y: -5.5rem;
    @include mobile {
      height: 7.7rem;
    }
  }
  .header,
  .card-block {
    border-radius: 0;
  }
  .header-row,
  .header-title {
    display: none;
  }
  .payment-header {
    height: 100%;
    @include mobile {
      display: grid;
    }
  }
  .payment-header__logo {
    top: 50%;
    transform: translateY(-50%);
  }
  .payment-header__title {
    padding-top: 5.2rem;
    @include mobile {
      padding-top: 0;
      display: grid;
      align-items: center;
    }
  }
  .btn {
    border-radius: 1.2rem;
  }

  .receipt-qr {
    width: 18rem;
    height: 18rem;
  }
  .update-price {
    justify-content: center;
  }
  .card-block__body {
    width: 100%;
    max-width: 47rem;
    margin-inline: auto;
    &.is-expired {
      .receipt-item,
      .receipt-qr {
        opacity: 0.4;
      }
    }
  }
}
