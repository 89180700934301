:root {
  // html
  --html-size: 62.5%;
  @media screen and (max-width: 380px) {
    --html-size: 45%;
  }

  // fonts
  --font-primary: 'IBM Plex Sans', sans-serif;
  --font-secondary: 'Montserrat', sans-serif;

  --N1_med_32: 500 3.2rem / 4rem var(--font-primary);
  --N2_med_28: 500 2.8rem / 3.2rem var(--font-primary);
  --N3_med_24: 500 2.4rem / 2.4rem var(--font-primary);
  --N4_med_20: 500 2rem / 2.4rem var(--font-primary);
  --N5_med_16: 500 1.6rem / 2rem var(--font-primary);

  --T1_med_48: 500 4.8rem / normal var(--font-secondary);
  --T2_med_32: 500 3.2rem / 4rem var(--font-secondary);
  --T3_med_24: 500 2.4rem / 2.8rem var(--font-secondary);
  --H1_med_20: 500 2rem/2.6rem var(--font-secondary);
  --H2_med_18: 500 1.8rem/2.2rem var(--font-secondary);
  --H3_med_16: 500 1.6rem/2.2rem var(--font-secondary);
  --H4_med_14: 500 1.4rem/2rem var(--font-secondary);
  --H5_med_12: 500 1.2rem/2.4rem var(--font-primary);
  --H6_med_11: 500 1.1rem/2.4rem var(--font-primary);

  --Bold_20: 700 2rem/1.6rem var(--font-primary);
  --Title_16: 500 1.6rem/2.4rem var(--font-primary);
  --Semibold_12: 600 1.2rem/2rem var(--font-primary);

  --Body1_reg_20: 400 2rem/2.6rem var(--font-primary);
  --Body1_reg_18: 400 1.8rem/2.4rem var(--font-primary);
  --Body1_light_16: 400 1.6rem/2.2rem var(--font-primary);
  --Body2_light_14: 300 1.4rem/2rem var(--font-primary);
  --Body2_light_12: 300 1.2rem/1.6rem var(--font-primary);
  --Body3_reg_16: 400 1.6rem/2.2rem var(--font-primary);
  --Body4_small_reg_12: 400 1.2rem/1.2rem var(--font-primary);
  --Body5_extasmall_reg_10: 400 1rem/1.2rem var(--font-primary);
  --Body5_extasmall_light_10: 300 1rem/1.6rem var(--font-primary);
  --Body6_bold_8: 700 0.8rem/.8rem var(--font-primary);
  --Body6_extasmall_reg_8: 400 0.8rem/1.6rem var(--font-primary);

  // day colors

  --main-color-day: #29017c;
  --main-color-txt-day: #6341e4;
  --main-txt-day: #140533;
  --dark-grey-day: #636c8d;
  --grey-day: #98a9c3;
  --light-grey-day: #e5eaf1;
  --transparent-grey-day: #f9f9f9;
  --light-main-day: #ebe5ff;
  --tab-back-day: #f0eef7;
  --yellow-day: #ffd748;
  --block-day: linear-gradient(0deg, var(--common-White) 0%, var(--common-White) 100%), #f9f9f9;
  --block-2-day: #f3f3f6;
  --page-bg-day: #f5f5f7;

  // common colors
  --common-hover: #401cc9;
  --common-SecondaryColor: #a175ff;
  --common-Red: #ff6262;
  --common-Green: #2bb795;
  --common-LightGreen: #70e1c6;
  --common-Orange: #f26d00;
  --common-Gold: #dfaf1e;
  --common-Shadow: #3a2b75;
  --common-Black: #110921;
  --common-White: #fff;
  --space-gradient: radial-gradient(126.34% 126.34% at 49.18% 126.34%, #4800db 1.77%, #130332 100%);

  // additional colors
  --common-DarkBlue: #0d00a2;
  --common-Blue: #0d40f2;
  --common-Cyan: #38a0ff;
  --common-Azure: #0092d0;
  --common-LightBlue: #bcd3ff;
  --common-Burgundy: #8b1f59;
  --common-OrangeRed: #cb4d4d;
  --common-LightOrange: #ff9c40;
  --common-Purple: #7201e3;
  --Ultraviolet: #6319ff;
  --Rose: #e7affc;
  --gray: #828282;

  // border-radius
  --border-radius: 1.6rem;
  --border-radius-rounded: 4.8rem;
  --border-radius-circle: 50%;
  --border-radius-btn: 3.6rem;
  --border-radius-secondary: 2.4rem;

  // buttons
  --btn-bg: var(--main-color-day);
  --btn-bg-hover: var(--common-hover);
  --btn-bg-press: linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%),
    var(--common-hover);
  --btn-text-color: var(--common-White);

  // alerts
  --alert-warning-bg: #f5f4fd;

  //timers
  --timerBg: rgba(56, 160, 255, 0.1);

  // toasts
  --toastify-bg: var(--common-White);

  // select
  --select-border-color: var(--grey-day);
  --select-group-border-color: var(--light-grey-day);
  --select-group-hover: var(--light-main-day);

  // payment
  --payment-bg: var(--common-White);
  --payment-radius: 4rem;

  // receipt
  --receipt-bg-color: var(--block-2-day);
  --receipt-radius: 1.6rem;

  --shadow-bg: rgba(58, 43, 117, 0.2);

  // scroll
  --scroll-bg-color: var(--grey-day);
  // transition
  --transition: 0.2s all ease-in-out;

}
