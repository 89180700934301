.not-found {
  height: 100%;
  overflow: hidden;
  position: relative;
  background: var(--space-gradient);
  &__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% - 17.8rem));
    width: 154.6rem;
    height: 154.6rem;
    border: 0.1rem solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    @include tablet-small {
      transform: translate(calc(-50% - 5.6rem), calc(-50% - 17.8rem));
    }
  }
  &-sonar {
    width: 154.6rem;
    height: 154.6rem;
    overflow: hidden;
    border-radius: 50%;
    animation: rotate360 6s infinite linear;
    background: conic-gradient(
      from 253deg at 50% 50%,
      rgba(255, 255, 255, 0.05) 46.69296205043793deg,
      rgba(255, 255, 255, 0.65) 104.20053720474243deg,
      rgba(255, 255, 255, 0.98) 105.77136754989624deg,
      rgba(255, 255, 255, 0.1) 107.36711025238037deg,
      rgba(255, 255, 255, 0.05) 155.04838585853577deg
    );
    &__circle {
      border-radius: 50%;
      border: 0.1rem solid var(--common-White);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      animation: pulse 6s infinite forwards;
      &._circle1 {
        width: 125rem;
        height: 125rem;
        opacity: 0.5;
        @include tablet-small {
          width: 92.8rem;
          height: 92.8rem;
        }
      }
      &._circle2 {
        width: 104.1rem;
        height: 104.1rem;
        opacity: 0.4;
        @include tablet-small {
          width: 77.4rem;
          height: 77.4rem;
        }
      }
      &._circle3 {
        width: 83.3rem;
        height: 83.3rem;
        opacity: 0.3;
        @include tablet-small {
          width: 61.9rem;
          height: 61.9rem;
        }
      }
      &._circle4 {
        width: 62.5rem;
        height: 62.5rem;
        opacity: 0.2;
        @include tablet-small {
          width: 46.4rem;
          height: 46.4rem;
        }
      }
      &._circle5 {
        width: 41.6rem;
        height: 41.6rem;
        opacity: 0.1;
        @include tablet-small {
          width: 30.9rem;
          height: 30.9rem;
        }
      }
    }
  }

  &-logo {
    background-image: url('/assets/logo.svg');
    width: 37.7rem;
    height: 15.2rem;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(calc(-50% + 11.2rem), calc(-50% - 17.8rem));
    @include tablet-small {
      transform: translate(-50%, calc(-50% - 17.8rem));
      width: 20.8rem;
      height: 8.4rem;
    }
  }
  &-text {
    width: 100%;
    z-index: 9;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, calc(-50% + 7.5rem));
    text-align: center;
    color: var(--common-White);
    &__title {
      font: var(--T1_med_48);
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.3rem;
      margin-bottom: 1.6rem;
      svg {
        width: 4.8rem;
        height: 4.3rem;
      }
      @include tablet-small {
        font: var(--H1_med_20);
        gap: 0.8rem;
        svg {
          width: 2rem;
          height: 1.8rem;
        }
      }
    }

    &__desc {
      max-width: 38rem;
      margin-inline: auto;
      font: var(--Body1_reg_18);
      text-align: center;
      margin-bottom: 3.2rem;
      @include tablet-small {
        font: var(--Body1_light_16);
      }
    }
    &__btn {
      border-radius: 3.6rem;
      min-width: 32.8rem;
      color: var(--common-White);
      text-align: center;
      padding: 1.4rem 4rem;
      font: var(--Body3_reg_16);
      border: 0.1rem solid var(--common-White);
      display: inline-block;
      cursor: pointer;
      outline: 0;
      text-decoration: none;
      min-height: 5rem;
      transition: var(--transition);
      &:hover {
        background: var(--btn-bg-press);
        border-color: transparent;
      }
      @include tablet-small {
        min-width: initial;
      }
    }
  }
}
@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.1);
    opacity: 0.1;
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}
