.btn {
  background: var(--btn-bg);
  border-radius: var(--border-radius-btn);
  @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--btn-text-color));
  padding: 1.4rem;
  min-width: 32.8rem;
  border: 0;
  outline: 0;
  display: inline-block;
  cursor: pointer;
  transition: var(--transition);
  &:hover {
    background: var(--btn-bg-hover);
  }
  &:active {
    background: var(--btn-bg-press);
  }
  &:disabled {
    opacity: 0.5;
    pointer-events: none;
    &:hover {
      background: var(--btn-bg);
    }
  }
  &._secondary {
    background: transparent;
    border: 0.1rem solid var(--main-color-day);
    color: var(--main-txt-day);
  }
  &._processing {
    background: var(--space-gradient);
    border-radius: 7.6rem;
    min-width: 100%;
    pointer-events: none;
    user-select: none;
  }

  @include mobile {
    min-width: 100%;
  }
}
