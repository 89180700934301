@mixin text($ff, $fs, $lh, $fw, $color) {
  font-family: $ff;
  line-height: $lh;
  font-weight: $fw;
  font-size: $fs;
  color: $color;
}


// breakpoints
$desktop-small: 1200px;
$tablet: 992px;
$tablet-small: 768px;
$mobile: 576px;

// MEDIA QUERIES
@mixin desktop-small {
  @media (max-width: $desktop-small) {
    @content;
  }
}

@mixin tablet-small {
  @media (max-width: $tablet-small) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}
