*,
*::after,
*::before {
  box-sizing: border-box;
}
html {
  font-size: var(--html-size);
}
body {
  font-family: var(--font-primary), -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu,
    Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html,
body,
#root,
.app-container {
  height: 100%;
}
