.spinner-full {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(6px);
}
.spinner-icon {
  width: 91px;
  height: 91px;
  position: relative;
  &::before,
  &::after {
    content: '';
    display: block;
    width: 95%;
    height: 95%;
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    border-radius: 5rem; /*1*/
    border: 5px solid transparent; /*2*/
    -webkit-mask: /*4*/ linear-gradient(var(--common-White) 0 0) padding-box, linear-gradient(var(--common-White) 0 0);
    -webkit-mask-composite: xor; /*5'*/
    mask-composite: exclude; /*5*/
  }
  &::before {
    animation: pulse 2.5s forwards infinite;
    background: linear-gradient(272.36deg, var(--main-color-day) -65.2%, rgba(41, 1, 124, 0) 96.58%) border-box; /*3*/
  }
  &::after {
    animation: pulse-second 2.5s forwards infinite;
    background: linear-gradient(249.95deg, var(--main-color-day) -39.32%, rgba(41, 1, 124, 0) 72.08%) border-box; /*3*/
  }
  svg {
    position: relative;
    z-index: 9;
    animation: pulse-logo 2.5s forwards infinite;
  }
}

@keyframes pulse {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.3);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes pulse-second {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  50% {
    transform: translate(-50%, -50%) scale(1.7);
  }
  100% {
    transform: translate(-50%, -50%) scale(1);
  }
}
@keyframes pulse-logo {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
