*::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
    background-color: var(--common-White);
}
* ::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 0.6rem var(--scroll-bg-color);
    background-color: var(--scroll-bg-color);
    border-radius: 0.2rem;
}
