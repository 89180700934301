.timer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6rem;
  background: var(--timerBg);
  @include mobile {
    margin-top: 0;
  }
  .timer__status,
  .timer__count {
    @include mobile {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
  &.is-expired {
    background: rgba(255, 215, 72, 0.3);
    padding: 0.8rem 3.2rem;
    @include mobile {
      padding: 0.8rem 1.6rem;
    }
    position: relative;
    .timer__status {
      display: inline-flex;
      align-items: center;
      gap: 0.8rem;
    }
    .timer__status {
      @include mobile {
          @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--main-txt-day));
      }
    }
    svg {
      width: 2rem;
      height: 2rem;
    }
    .timer__tooltip {
      display: inline-flex;
      align-items: center;

      cursor: pointer;
      &:hover {
        .timer__tooltip-body {
          opacity: 1;
          transform: scale(1);
        }
        svg {
          circle:first-child {
            fill: var(--main-txt-day);
          }
          path,
          circle {
            fill: var(--common-White);
            stroke: var(--common-White);
          }
        }
      }
      @include mobile {
        display: none;
      }
      svg {
        width: 1.6rem;
        height: 1.6rem;
        * {
          transition: var(--transition);
        }
      }
      .timer__tooltip-body {
        transform: scale(0);
        opacity: 0;
        transition: var(--transition);
        position: absolute;
        left: 3.2rem;
        z-index: 9;
        top: calc(100% + 0.8rem);
        max-width: 36rem;
        border-radius: 0.8rem;
        background: var(--main-txt-day);
        padding: 0.8rem 1.2rem;
        @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--common-White));
      }
    }
    .btn {
      min-width: auto;
      align-items: center;
      display: inline-flex;
      gap: 0.8rem;
      padding: 0.6rem 1.2rem 0.6rem 0.8rem;
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }

    .timer__body {
      display: flex;
      width: 100%;
      max-width: 47rem;
      margin-inline: auto;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 0.8rem;
    }
  }
  &__status {
    @include text(var(--font-secondary), 1.6rem, 2.2rem, 500, var(--main-txt-day));
    margin-right: 0.8rem;
  }

  &__count {
    width: 4.5rem;
    @include text(var(--font-secondary), 1.6rem, 2.2rem, 500, var(--main-txt-day));
    margin-right: 0.7rem;
  }

  &__icon {
    display: flex;
  }
}
.receipt {
  padding: 1rem 1.5rem;
  .alert {
    margin-top: 2rem;
    margin-bottom: 2rem;
    @include mobile {
      margin-bottom: 1.2rem;
    }
    &__text {
      @include mobile {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }
  }
}
.receipt-qr {
  margin-left: auto;
  margin-right: auto;
  width: 12.6rem;
  height: 12.6rem;
  background: var(--common-White);
  border-radius: var(--receipt-radius);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    transform: scale(1.15);
  }
}
.receipt-header {
  margin: 2.2rem 0 2.4rem;
  text-align: center;

  &__icon {
    width: 7.2rem;
    height: 7.2rem;
    margin: 0 auto 1.6rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font: var(--H2_med_18);
    color: var(--main-txt-day);
    &._maxwidth {
      max-width: 335px;
      margin-inline: auto;
      @include mobile {
        max-width: 26rem;
      }
    }
    &._maxwidth-payout {
      max-width: 335px;
      margin-inline: auto;
      @include mobile {
        max-width: 30rem;
      }
    }
    &._maxwidth-lg {
      max-width: 228px;
      margin-inline: auto;
      @include mobile {
        max-width: 26rem;
      }
    }
  }

  &__text {
    margin-top: 0.8rem;
    font: var(--Body3_reg_16);
    color: var(--main-txt-day);
  }
}
.receipt-item {
  display: flex;
  align-items: flex-end;
  &.is-expired {
    opacity: 0.4;
  }
  &:not(:first-child) {
    margin-top: 1rem;
    @include mobile {
      margin-top: 0.8rem;
    }
  }
  &__icon {
    width: 2.4rem;
    height: 2.4rem;
    flex-shrink: 0;
    margin-right: 1.2rem;
  }

  &__short-title {
    margin-right: 0.8rem;
    @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
  }

  &__full-title {
    @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--dark-grey-day));
    @include mobile {
      margin-bottom: 0.4rem;
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &__price {
    display: flex;
    gap: 0.4rem;
    align-items: center;
    margin-left: auto;
  }

  &__value {
    @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--main-txt-day));
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &__currency {
    @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--dark-grey-day));
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &__rate {
    margin-left: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__rate-title {
    margin-right: 0.8rem;
    @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--main-txt-day));
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.6rem;
    }
  }
  &__rate-value {
    @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--dark-grey-day));
    @include mobile {
      font-size: 1.2rem;
      line-height: 1.2rem;
    }
  }

  &__rate-lock {
    width: 2rem;
    height: 2rem;
    margin-right: 1.6rem;
  }
}
.result {
  margin-top: 2rem;
  background: var(--receipt-bg-color);
  border-radius: 1.6rem;
  padding: 1.6rem 0;
  @include mobile {
    padding: 1.2rem 0;
    margin-top: 1.2rem;
  }
  &._withdrawal {
    .result-item {
      &:not(:last-child) {
        margin-bottom: 0.8rem;
      }
      &._total {
        .result-item__title {
          @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
        }
        .result-item__value {
          @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
        }
      }
      &__title {
        margin-bottom: 0;
        @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--dark-grey-day));
        @include mobile {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
      &:last-child {
        .result-item__border {
          border-bottom: 0.1rem solid var(--select-border-color);
          margin-bottom: 0.8rem;
        }
      }

      &__value {
        text-align: right;
        word-break: break-word;
        @include text(var(--font-primary), 1.4rem, 2rem, 400, var(--main-txt-day));
        @include mobile {
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
      }
    }
  }
}
.result-item {
  position: relative;
  overflow: hidden;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  @include mobile {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
  &.is-copied {
    .copied-box {
      transform: translateX(0);
      opacity: 1;
    }
  }
  .copied-box {
    position: absolute;
    width: calc(100% - 1.6rem);
    height: 100%;
    opacity: 0;
    display: flex;
    background: var(--common-Cyan);
    border-radius: 1.2rem;
    justify-content: center;
    align-items: center;
    left: 0.8rem;
    z-index: 9;
    gap: 0.8rem;
    transform: translateX(100%);
    transition: var(--transition);
    @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--common-White));
  }

  &:not(&:last-child) {
    margin-bottom: 1rem;
    @include mobile {
      margin-bottom: 0.8rem;
    }
  }

  &__title {
    margin-bottom: 0.8rem;
    @include text(var(--font-primary), 1.2rem, 1.2rem, 400, var(--main-txt-day));
    @include mobile {
      line-height: 1.6rem;
      margin-bottom: 0.4rem;
    }
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;
  }

  &__value {
    word-break: break-word;
    @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
    @include mobile {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__check-rounded {
    background: var(--common-Cyan);
    border-radius: var(--border-radius-circle);
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__copy {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    position: relative;
    &-icon {
      opacity: 1;
      transition: 0.2s opacity ease-in-out;
      svg {
        width: 2rem;
        height: 2rem;
      }
    }
    &-check {
      opacity: 0;
      background: var(--common-Green);
      border-radius: var(--border-radius-circle);
      width: 2rem;
      height: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      pointer-events: none;
      position: absolute;
      top: 50%;
      transition: 0.2s opacity ease-in-out;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
.update-price {
  background: rgba(255, 215, 72, 0.3);
  padding: 0.6rem 3.2rem;
  display: flex;
  align-items: center;

  &__icon {
    width: 2rem;
    height: 1.8rem;
    margin-right: 0.8rem;
  }

  &__text {
    @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
    margin-right: 2.5rem;
    @include mobile {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }

  &__btn {
    button {
      display: inline-flex;
      background: var(--btn-bg);
      border-radius: 3.2rem;
      gap: 0.8rem;
      align-items: center;
      cursor: pointer;
      border: 0;
      padding: 0.6rem 1rem;
      @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--common-White));
    }
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }
}

.recheck {
  margin-top: 1.6rem;
  display: grid;
  gap: 0.8rem;
  text-align: center;
  &__title {
    @include text(var(--font-secondary), 1.8rem, 2.2rem, 500, var(--main-txt-day));
  }
  &__desc {
    @include text(var(--font-primary), 1.6rem, 2.2rem, 400, var(--main-txt-day));
    @include mobile {
      font-size: 1.4rem;
      line-height: 2rem;
    }
  }
}
.payout-btn {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  gap: 1.6rem;
  padding: 0 1.5rem;
  .btn {
    min-width: unset;
    flex: 1 1 50%;
  }
}
.payout-completed {
  max-width: 35.8rem;
  margin-inline: auto;
  margin-top: 1.6rem;
  text-align: center;
  @include text(var(--font-secondary), 1.8rem, 2.2rem, 500, var(--main-txt-day));
}
.payout-stepbar {
  max-width: 38.4rem;
  margin: 2rem auto 0 auto;
}
.network-name {
  margin-right: 0.2rem;
}
.payout-processing {
  padding: 0 1.5rem;
}
