.coin {
  display: inline-block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  &.TRUMP {
    background-image: url('/assets/icons/TRUMP.png');
    background-size: cover;
  }
  &.MELANIA {
    background-size: cover;
    background-image: url('/assets/icons/MELANIA.png');
  }
}
